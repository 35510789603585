import React, { useState, useEffect } from "react";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag";
import Header from "../components/Header/Header";
import PropertyBreadcrumb from "../components/PropertyDetails/PropertyBreadcrumb";
import PropertyBanner from "../components/PropertyDetails/PropertyBannerTwo";
import PropertyDescription from "../components/PropertyDetails/PropertyDescription";
import SimilarProperties from "../components/PropertyDetails/SimilarProperties";
import Page404 from "../pages/404";
import BookaViewing from "../components/PropertyDetails/BookaViewing";
import { propertyDetImage } from "../components/Seo/propertyDetailsSEO";
import Footer from "../components/Footer/Footer";
import BrandLogo from "../images/icons/logo_black.svg";
import { capitalize } from "lodash";
import SEO from "../components/Seo/seo"

const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!) {
    properties(where:{id: $id}) {
      id
      search_type
      status
      department
      publish
      price
      price_qualifier
      currency
      display_address
      area
      bedroom
      bathroom
      reception
      building
      extra
      room_details
      floor_area
      floorarea_min
      floorarea_max
      floorarea_type
      floorplan
      crm_negotiator_id
      latitude
      longitude
      accomadation_summary
      long_description
      images
      imagetransforms
      ggfx_results {
        id
        content_type
        transforms
        src_import_url
        src_cftle
        field
      }
      video_tour
      virtual_tour
    }
  }
`;

const PropertyDetailsTemplate = (props) => {

    const STAGE_ENV = process.env.GATSBY_STRAPI_GGFX_ENV || "i.dev"
    //
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    let myid_filt = pageurl.split('?');
    let mypageurl = myid_filt[0];
    let property_slug = mypageurl.split('-');
    let id = property_slug[property_slug.length - 1];
    id = id.replace("/", "");
  
    const { loading, error, data } = useQuery(PROPERTY_DETAILS, {
        variables: { id: id }
    });

    // Loading logo
    if (loading) return (
        <section className={"loader-wrapper"}>
            <div id="loader-wrapper">
                <div id="loader" className="new-loader">
                    <img className="logo-white loader-logo" src={BrandLogo} alt="logo"/>
                </div>
            </div>
        </section>
    );
    // Loading logo

    // PropertyStatus logic
    const propStatus = data => {
      var property, propertyStatus, propertyActive

      property = data
      propertyStatus = property?.status
      propertyActive = property?.publish

      if (propertyStatus === "valuation" && propertyActive === false) {
        return true
      } else if (propertyStatus !== "valuation" && propertyActive === true) {
        return true
      } else {
        return false
      }
    }
    const PropertyStatus =
      data &&
      data?.properties &&
      data?.properties?.length > 0 &&
      propStatus(data?.properties[0])
    // PropertyStatus logic

    return (
        <React.Fragment>
            <div className="property-details-template">
                <Header layout={"property-details-template"} />

                {
                    (PropertyStatus) ?
                    (
                        data.properties.map((data, i) => {
                            const building = data?.building
                            var searchaction = data.search_type === "sales"?"for sale":"to let"            
                            var metatile = ""+capitalize(building ? building?.replace(/-/g, ' ') : 'property')+" "+searchaction+" with "+data.bedroom+" bedrooms in "+data.display_address+" at AED "+data.price.toLocaleString();
                            var metadesc = "Know the details of "+capitalize(building?.replace(/-/g, ' '))+" "+searchaction+" with "+data.bedroom+" bedrooms in "+data.display_address+" at AED "+data.price.toLocaleString()+". Book a viewing to get assistance in finding the right "+capitalize(building?.replace(/-/g, ' '))+" for you.";

                            return (
                                <>
                                    <SEO title={metatile} description={metadesc} />

                                    <PropertyBreadcrumb propertyData={data} />

                                    <PropertyBanner propertyData={data} normalImg />

                                    <PropertyDescription propertyData={data} />

                                    <SimilarProperties propertyData={data} />

                                    <BookaViewing propertyData={data} />
                                </>
                            )
                        })
                    )
                    :
                    <Page404 publish = {data?.properties[0]?.publish}/>
                }

                <Footer layout={"property-details"} categoryType={data.department === "residential" ? "category1" : "category4"}  />
            </div>
        </React.Fragment>
    )
}

export default PropertyDetailsTemplate